import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Paintball club SplatterZone
			</title>
			<meta name={"description"} content={"Experience the thrill, conquer the field - SplatterZone, where paintball adventures begin"} />
			<meta property={"og:title"} content={"Paintball club SplatterZone"} />
			<meta property={"og:description"} content={"Experience the thrill, conquer the field - SplatterZone, where paintball adventures begin"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
		</Helmet>
		<Components.Header />
		<Components.Hero background="linear-gradient(320deg,rgba(0,0,0,0) 0%,rgba(0, 0, 0, 0.76) 72.4%),--color-darkL2 url(https://uploads.quarkly.io/65e71741926d910020edb149/images/3-3.jpg?v=2024-03-06T10:22:29.442Z) center/cover">
			<Override slot="text1">
				FAQ
			</Override>
			<Override slot="text" display="none" />
		</Components.Hero>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-11">
			<Text
				margin="0px 0px 70px 0px"
				font="normal 900 52px/1.2 --fontFamily-sans"
				color="#0e1940"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
				sm-font="normal 900 36px/1.2 --fontFamily-sans"
			>
				Frequently Asked Questions
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				lg-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							What types of matches do you offer?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							We offer a variety of matches, including standard skirmishes and themed scenario games, each designed to provide a unique and thrilling paintball experience.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Can I customize my paintball experience?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Absolutely! Talk to our team about how to personalize your event, whether it's a corporate team-building session or a birthday party with friends.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Is paintball suitable for beginners?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Absolutely! Our fields are suitable for players of all skill levels, from beginners to seasoned veterans, ensuring that everyone can enjoy the thrill of paintball.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Are there facilities on-site?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Yes, we offer amenities such as rest areas and snack bars to keep you comfortable and hydrated during your paintball adventure.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							What equipment is included in the rental fee?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							We provide top-quality paintball markers, masks, and protective gear to ensure your safety and enjoyment on the battlefield.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							How do I book a paintball session?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Simply contact our team via phone or email to schedule your paintball session. We're here to help you plan the perfect outing for your group.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde6288e2e8e002178b6f4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});